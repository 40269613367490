@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.header {
  font-family: "Monsterrat", sans-serif;
  font-weight: 700;
  text-align: center !important;
}

body {
  background-color: #fdfaf6;
  align-items: center;
  text-align: center;
  font-weight: 300;
}

.font {
  font-weight: 300;
  font-family: "Monsterrat", sans-serif;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #939b7b;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #787d67;
}

.footer-link {
  color: #e3dfd6;
  text-decoration: none;
  font-size: small;
}

.custom-btn.btn {
  background-color: #939b7b;
  border-color: #939b7b;
  color: white;
  transition: background-color 0.3s ease;
}

.custom-btn.btn:hover {
  background-color: white;
  border-color: white;
  color: #58654b;
}

.custom-btn.btn:focus,
.custom-btn.btn:active {
  background-color: #58654b;
  border-color: #58654b;
  color: white;
}

.custom-btn.btn:active:hover {
  background-color: white;
  border-color: white;
  color: #58654b;
}

.custom-btn.btn:disabled {
  background-color: white;
  border-color: white;
  color: #58654b;
}

.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
  width: 80%;
  height: 80%;
  max-width: 800px;
  max-height: 600px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 1001;
}

.hover-card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.image-wrapper {
  position: relative;
  overflow: hidden;
}

.card-body img {
  transition: transform 0.3s ease;
}

.image-wrapper.active img {
  transform: scale(1.1);
}

.hover-overlay {
  position: absolute;
  inset: 0;
  background: rgba(92.2, 89, 85.9, 0.8);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  transform: translateY(100%);
  transition: transform 0.5s ease, opacity 0.3s ease;
  opacity: 0;
  pointer-events: none;
}

.image-wrapper.active .hover-overlay {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

@media (min-width: 768px) {
  .hover-card:hover .card-body img {
    transform: scale(1.1);
  }

  .hover-card:hover .hover-overlay {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto;
  }

  .image-wrapper.active .hover-overlay {
    transform: translateY(100%);
    opacity: 0;
    pointer-events: none;
  }
}

/* Hide Read More button on larger screens */
@media (min-width: 768px) {
  .read-more-btn {
    display: none;
  }
}

@media (max-width: 768px) {
  .carousel {
    height: 30vh;
    display: flex;
  }

  .hover-overlay p {
    font-size: 0.75rem;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .carousel {
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.carousel-item img {
  width: 100vw;
  height: auto;
  max-height: 75vh;
  object-fit: contain;
}

/* List Group Items */
.fade-in {
  opacity: 0;
}

.fade-in-active {
  opacity: 1;
  animation: fadeIn 3s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.footer {
  background-color: white !important;
  padding-bottom: 0px !important;
}

.footer .container-fluid {
  box-shadow: 0px -4px 4px 0 rgba(0, 0, 0, 0.2) !important;
  background-color: white !important;
  max-height: 50% !important;
  font-size: small !important;
}

.social a {
  padding: 1rem;
  display: inline-block;
  color: inherit;
}

.social a:first-child {
  padding-left: 0;
}

.about {
  width: 100vw;
  overflow: hidden;
  background-color: #939b7b;
  color: white;
  align-items: center;
}

.about-personal {
  width: 100vw;
  overflow: hidden;
  background-color: #ebe3db;
  align-items: center;
}
